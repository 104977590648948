export const apiCallsInProgress = 0;
export const brands = [];
export const brandCount = 0;
export const selectedBrand = {};
export const features = [];
export const featureValues = [];
export const selectedFeatureValues = [];
export const categories = [];
export const selectedCategory = { topId: 0, catId: 0 };
export const previousCategoryFilters = {};
export const products = { content: [] };
export const product = {};
export const catalogRequestTime = {};
export const filters = {};
export const previousFilters = {};
export const user = { name: 'Guest', loggedIn: false, checked: false };
export const reportList = { content: [] };
export const report = {};
export const orders = { content: [] };
export const availability = false;
export const featureProducts = {};
export const userInfo = {};
export const userParentInfo = {};
export const catalog = [];
export const listing = ['N/A'];
export const cart = {};
export const shoppingLists = null;
export const downloadedShoppingList = {};
export const blogPosts = [];
export const blog = null;
export const newsEntries = [];
export const landingPageBanners = [];
export const catalogForCorporateGifts = [];
export const corporateGiftsFeatures = [];
export const vendorAgreement = {};
export const showUserMenu = false;
export const showShoppingListPopup = false;
